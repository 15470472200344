import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { VisuallyHidden } from '@overdose/components'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { ProductList, PromoBannerCardSummmary } from 'shared-types'
import { Button, FeaturedProductCard, ProductCard } from '~/components'
import { CategoryTileProps } from '~/components/CategoryTile'
import CategoryTiles from '~/components/CategoryTiles'
import DisruptorPromoCard from '~/components/DisruptorPromoCard/DisruptorPromoCard'
import { Image } from '~/components/Image'
import ImageOverlayLinks from '~/components/ImageOverlayLinks/ImageOverlayLinks'
import { NoProductsFound } from '~/components/NoProductsFound'
import { ProductListFilters } from '~/components/ProductListFilters/ProductListFilters'
import ProductListRelatedKeywords from '~/components/ProductListRelatedKeywords'
import RichText from '~/components/RichText/RichText'
import { SectionContainer } from '~/components/SectionContainer'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { getImageSrcSet } from '~/helpers'
import { useAlgoliaSearchInsignts } from '~/hooks'
import { GTM, GTMEvent } from '~/lib/gtm/GTM'
import {
  FilterProvider,
  useFilterContext,
} from '~/providers/FilterProvider/FilterProvider'
import {
  PaginationProvider,
  PaginationProviderInfo,
  usePaginationContext,
} from '~/providers/PaginationProvider/PaginationProvider'
import {
  ProductListingCoreProps,
  ProductListingHeadProps,
  ProductListingProps,
} from '~/sections/ProductListing/ProductListing.types'
import styles from './ProductListing.module.css'

const CONST_ONE_COLUMN = 1
const CONST_THREE_COLUMN = 3

const ProductListingHead = ({
  backgroundImage,
  categoryName,
  categoryNameAlignment,
  paragraph,
  hasSearchQuery = false,
  onViewMore,
}: ProductListingHeadProps) => {
  const desktopBannerImage = backgroundImage?.desktop || backgroundImage?.mobile
  const mobileBannerImage = backgroundImage?.mobile || backgroundImage?.desktop

  const bannerStyle = useMemo(() => {
    const mobileBannerWidth =
      mobileBannerImage?.width != null
        ? `${mobileBannerImage?.width}px`
        : '100%'
    const mobileBannerHeight =
      mobileBannerImage?.height != null
        ? `${mobileBannerImage?.height}px`
        : '120px'
    const desktopBannerWidth =
      desktopBannerImage?.width != null
        ? `${desktopBannerImage?.width}px`
        : '100%'
    const desktopBannerHeight =
      desktopBannerImage?.height != null
        ? `${desktopBannerImage?.height}px`
        : '100%'

    return {
      '--mobile-banner-width': mobileBannerWidth,
      '--mobile-banner-height': mobileBannerHeight,
      '--desktop-banner-width': desktopBannerWidth,
      '--desktop-banner-height': desktopBannerHeight,
    } as React.CSSProperties
  }, [desktopBannerImage, mobileBannerImage])

  return (
    <>
      {desktopBannerImage?.src && (
        <div
          className={classNames(
            'relative mt-2 mb-6 max-w-full',
            styles.bannerImage
          )}
          style={bannerStyle}>
          <ImageOverlayLinks
            desktopImage={desktopBannerImage}
            mobileImage={mobileBannerImage}
          />
          <Image
            className={classNames(
              'object-cover rounded lg:!rounded-lg w-full h-full'
            )}
            src={desktopBannerImage.src}
            addSrcSet={false}
            alt={desktopBannerImage.altText}
            width={desktopBannerImage.width}
            height={desktopBannerImage.height}
            sizes='1280px'
            sources={
              <>
                <source
                  srcSet={getImageSrcSet(backgroundImage.mobile.src, [
                    {
                      intrinsicImageSize: '800',
                      width: '800',
                    },
                  ])}
                  media='(max-width: 768px)'
                />
                <source
                  srcSet={getImageSrcSet(backgroundImage?.tablet?.src, [
                    {
                      intrinsicImageSize: '1100',
                    },
                  ])}
                  media='(max-width: 1024px)'
                />
              </>
            }
          />
        </div>
      )}

      {!hasSearchQuery && (
        <Typography
          variant={TypographyVariant.Heading3}
          tag={TypographyTag.h1}
          className={classNames(
            {
              'flex justify-center': categoryNameAlignment === 'center',
            },
            'font-display-large-desktop-font-family text-primary-blue text-2xl leading-display-large-desktop font-bold',
            'lg:text-display-x-large-desktop lg:leading-9'
          )}>
          {categoryName || ''}
        </Typography>
      )}
      {paragraph && (
        <div className='mt-2 flex gap-4 flex-col items-start'>
          <Typography
            tag={TypographyTag.p}
            className='flex justify-center'
            variant={TypographyVariant.BodyRegularExtraLineHeight}>
            {paragraph}
          </Typography>
          <Button
            status='primary'
            variant='ghost'
            theme={{ root: 'p-0 h-auto' }}
            style={{ '--btn-ghost-text-decoration': 'underline' }}
            onClick={onViewMore}>
            Learn More
          </Button>
        </div>
      )}
    </>
  )
}

const ProductListing: FC<ProductListingCoreProps> = ({
  products: initialProducts,
  width,
  sort,
  categoryNameAlignment = 'left',
  paragraph,
  relatedKeywords,
  categoryDetails,
  backgroundImage,
  queryId,
}) => {
  const {
    searchType,
    searchQuery,
    searchFilters,
    selectedFilterMap,
    selectedSortOption,
  } = useFilterContext()

  const {
    category,
    pagination,
    setPagination,
    renderPreviousPagination,
    renderNextPagination,
  } = usePaginationContext()

  const { updateLatestQueryId, sendViewProductsEvent, sendFilterEvent } =
    useAlgoliaSearchInsignts()

  const [products, setProducts] = useState<ProductList>(initialProducts)

  const [prevProducts, setPrevProducts] = useState(initialProducts)
  const router = useRouter()

  const pageNumber = Number(router?.query?.p) || null
  const [initialPage, setInitialPage] = useState(pageNumber)
  const longDescriptionRef = useRef(null)

  const scrollToDescription = () => {
    if (longDescriptionRef.current) {
      longDescriptionRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  if (pageNumber === 1 && initialPage) {
    setInitialPage(null)
  }

  if (initialProducts !== prevProducts) {
    setPrevProducts(initialProducts)
    setProducts(initialProducts)
    setPagination((prevPagination) => {
      return {
        ...prevPagination,
        currentPage: pageNumber || 1,
      }
    })
  }

  useEffect(() => {
    updateLatestQueryId(queryId)
  }, [queryId, updateLatestQueryId])

  useEffect(() => {
    sendFilterEvent(selectedFilterMap, 'view')
  }, [selectedFilterMap, sendFilterEvent])

  useEffect(() => {
    if (!searchQuery) {
      return
    }
    GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
    GTM.dispatch(GTMEvent.VIEW_SEARCH_RESULTS, {
      products,
      searchTerm: searchQuery?.selectedOptions?.[0],
      searchResultCount: pagination.totalProducts ?? 0,
      searchFilters: searchFilters || 'NA',
      searchType,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters])

  const dispatchViewItemListWasCalled = useRef(false)
  const algoliaProductViewEventWasCalled = useRef(false)

  useEffect(() => {
    if (!products || dispatchViewItemListWasCalled.current) {
      return
    }
    dispatchViewItemListWasCalled.current = true

    GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
    GTM.dispatch(GTMEvent.VIEW_ITEM_LIST, {
      products,
      category,
    })
  }, [category, products])

  useEffect(() => {
    if (!products || algoliaProductViewEventWasCalled.current) {
      return
    }
    algoliaProductViewEventWasCalled.current = true
    sendViewProductsEvent(products)
  }, [products, sendViewProductsEvent])

  const productsAvailable = !!products?.length

  const renderCategoryDetails = categoryDetails && (
    <div
      ref={longDescriptionRef}
      className='flex flex-col items-start gap-4 mb-20'>
      <Typography
        tag={TypographyTag.p}
        className='flex justify-center'
        variant={TypographyVariant.BodyXLargeBold}>
        {categoryDetails.title}
      </Typography>
      <RichText content={categoryDetails.description.json} />
    </div>
  )

  const renderRelatedKeywords = relatedKeywords && (
    <div className='mb-16 lg:mb-20'>
      <ProductListRelatedKeywords {...relatedKeywords} />
    </div>
  )

  const showCategoriesAndRelatedWords = !!(
    (relatedKeywords && Object?.keys(relatedKeywords).length) ||
    (categoryDetails && Object?.keys(categoryDetails).length)
  )

  const areCategoriesAvailable = category?.children?.length > 0

  const categoryChildren = category?.children?.slice().sort((a, b) => {
    return parseFloat(a.orderHint) - parseFloat(b.orderHint)
  })

  return (
    <SectionContainer
      width={width}
      wrapperClassName={classNames('productListingSection', {
        'mb-8 lg:mb-20': !showCategoriesAndRelatedWords,
      })}>
      <ProductListingHead
        backgroundImage={backgroundImage}
        hasSearchQuery={!!searchQuery}
        categoryName={category?.name}
        categoryNameAlignment={categoryNameAlignment}
        paragraph={paragraph}
        onViewMore={scrollToDescription}
      />
      {areCategoriesAvailable && (
        <div className='py-4 md:mt-2 bg-white'>
          <CategoryTiles
            tiles={categoryChildren.map((childCategory): CategoryTileProps => {
              return {
                title: childCategory.name,
                action: {
                  title: childCategory.name,
                  href: childCategory.url,
                },
                image: childCategory.thumbnail,
                size: 'lg',
                blendBackground: false,
              }
            })}
          />
        </div>
      )}
      {!!searchQuery && (
        <div className='flex flex-col md:flex-row gap-3.5 md:items-baseline mb-8 mt-2'>
          <Typography
            variant={TypographyVariant.Heading3}
            tag={TypographyTag.h1}
            className={classNames(
              styles.searchTitle,
              'flex flex-col 2xl:flex-row 2xl:items-baseline gap-2',
              {
                'flex justify-center': categoryNameAlignment === 'center',
              }
            )}>
            {!category?.url && (
              <span className='font-normal'>Showing results for </span>
            )}
            <span className='md:leading-display-large-desktop'>
              {category?.name || ''}
            </span>
          </Typography>
          <Typography
            variant={TypographyVariant.BodyRegular}
            tag={TypographyTag.div}
            className='text-primary-blue'>
            ({pagination?.totalProducts || 0} Products Found)
          </Typography>
        </div>
      )}
      {productsAvailable && (
        <div
          className={classNames({
            'mt-6 md:mt-8': !areCategoriesAvailable,
            'mt-2 md:mt-4': areCategoriesAvailable,
          })}>
          <ProductListFilters sort={sort} />
        </div>
      )}

      {initialPage > 1 && (
        <div className='flex justify-center mt-6'>
          {renderPreviousPagination()}
        </div>
      )}
      <Typography variant={TypographyVariant.Heading2} tag={TypographyTag.h2}>
        <VisuallyHidden>Featured Products</VisuallyHidden>
      </Typography>
      <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-x-4 gap-y-4 md:gap-y-10 pt-6 pb-10'>
        {productsAvailable ? (
          products.map((product: PromoBannerCardSummmary, index) => {
            let imagePriority = false
            if (index < Number('6')) {
              imagePriority = true
            }

            if (product?.isFeatured) {
              return (
                <div
                  key={index}
                  className={classNames({
                    'col-span-2 lg:col-span-3':
                      product.columnSpan === CONST_THREE_COLUMN,
                    'col-span-1': product?.columnSpan === CONST_ONE_COLUMN,
                  })}>
                  <FeaturedProductCard
                    {...product}
                    tags={product?.tags}
                    showAddToCartButton={product?.showAddToCartButton}
                    finalPrice={product?.finalPrice}
                    regularPrice={product?.regularPrice}
                    image={product?.thumbnail}
                    url={product?.url}
                    imagePriority={imagePriority}
                    category={category}
                    availableForDelivery={product?.availableForDelivery}
                    inStockText={product?.inStockText}
                  />
                </div>
              )
            }
            if (product?.isPromoBanner) {
              return (
                <div key={index} className='col-span-2 lg:col-span-3'>
                  <DisruptorPromoCard {...product} />
                </div>
              )
            }

            return (
              <div key={index} className='col-span-1'>
                <ProductCard
                  title={product?.title}
                  url={product?.url}
                  {...product}
                  sku={product?.sku}
                  finalPrice={product?.finalPrice}
                  regularPrice={product?.regularPrice}
                  showAddToCartButton
                  thumbnail={product?.thumbnail}
                  index={index}
                  category={category}
                  imagePriority={imagePriority}
                  availableForDelivery={product?.availableForDelivery}
                  inStockText={product?.inStockText}
                  tags={product?.tags}
                  titleSection={category?.name || 'category'}
                  searchIndex={index + 1}
                  queryId={queryId}
                  showCompactAddToCart={false}
                />
              </div>
            )
          })
        ) : (
          <div className='flex justify-start'>
            <NoProductsFound />
          </div>
        )}
      </div>
      <div className='flex justify-center'>
        {renderNextPagination({
          productCnt: products?.length,
          filterInfo: {
            searchType,
            searchQuery,
            searchFilters,
            selectedSortOption,
            selectedFilterMap,
            setProducts,
          },
        })}
      </div>
      {showCategoriesAndRelatedWords && (
        <>
          <hr className='border-t my-20' />
          {renderCategoryDetails}
          {renderRelatedKeywords}
        </>
      )}
    </SectionContainer>
  )
}

const ProductListingWithPagination: FC<
  ProductListingCoreProps & PaginationProviderInfo
> = (props) => {
  const { category, totalPages, currentPage, totalProducts, ..._props } = props

  const values = useMemo(() => {
    return {
      category,
      totalPages,
      currentPage,
      totalProducts,
    }
  }, [category, currentPage, totalPages, totalProducts])

  return (
    <PaginationProvider {...values}>
      <ProductListing {..._props} />
    </PaginationProvider>
  )
}

export const ProductListingWrapper: FC<ProductListingProps> = (props) => {
  const { selectedFilters, filters, searchType, selectedSort, ..._props } =
    props

  const { asPath } = useRouter()
  const [basePath, params] = asPath.split('?')
  const filteredParams = params?.split('&').find((param) => {
    return param.includes('q=')
  })

  // Key ensures the PLP filters reset when search query changes
  const key = `${basePath}${filteredParams || ''}`

  return (
    <FilterProvider
      filters={filters}
      searchType={searchType}
      selectedFilters={selectedFilters}
      selectedSort={selectedSort}
      key={key}>
      <ProductListingWithPagination {..._props} />
    </FilterProvider>
  )
}

export default ProductListingWrapper
